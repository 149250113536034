.apartment_main{
    width: 100%;
    padding-top: 134px;
}

.apartment_main_inner{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding-bottom: 154px;
}

.fixInnerPaddingForNewApartments{
    padding-bottom: 10px;
}

.apartment_main_inner_left{
    box-sizing: border-box;
    width: 50%;
    position: relative;
    margin-left: 114px;
}

.apartment_main_inner_left img{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.apartment_main_inner_right{
    box-sizing: border-box;
    padding-left: 100px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.apHeader{
    padding-bottom: 45px;

    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    color: #000000;
}

.numberRounded{
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: #029FA0;
    
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 15px;

    color: #F4F3E7;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}





.roomType{
    display: none;
}

.room{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 160px;
    margin-top: 20px;

    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: #000000;
}

.number_name{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.pointers{
    width: 31px;
    height: 31px;
    background-color: #029FA0;
    position: absolute;
    border-radius:50%;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-weight: 600;
    text-align: center;
}

.default{
    font-weight: 400;
    transition: all 0.4s ease;
}

.bold{
    font-weight: 900;
    transition: all 0.4s ease;
}

.to_back{
    display: none;
    align-items: center;
    padding-left: 24px;
    padding-bottom: 60px;
    
}

.to_back img{
    width: 12px;
    height: 12px;
    
}

.to_back p{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
}

.apartment_rooms{
    width: 570px;
    max-width: 100%;
}



@media (min-width:1400px) {
    .apartment_main_inner_right{
        padding-left: 140px;
    }
}

@media (min-width:1600px) {
    .apartment_main_inner_right{
        padding-left: 350px;
    }
}

@media (max-width:769px) {
    
    .apartment_main_inner{
        flex-direction: column;
    }
    .apartment_main_inner_left{
        box-sizing: border-box;
        width: 100%;
        padding-left: 0px;
        margin-left:0px;
    }

    .fixInnerPaddingForNewApartments{
        padding-bottom: 154px;
    }

    .apartment_main_inner_right{
        width: 100%;
        padding-left: 0;
    }
    .room{
        padding-left: 30px;
        padding-right: 20px;
        margin-top: 0px;
    }
    .apHeader{
        text-align: center;
        padding-bottom:60px;
        padding-top: 45px;
    }
    .room p{
        font-size: 15px;
    }
    .numberRounded{
        width: 25px;
        height: 25px;
    }
    .number_name{
        gap: 15px;
    }
    .pointers{
        width: 25px;
        height: 25px;
    }

    .to_back{
        display: flex;
        align-items: center;
        padding-left: 24px;
        padding-bottom: 60px;
    }
    .apartment_rooms{
        height: 40px;
    }
}



