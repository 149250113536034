.features{
    box-sizing: border-box;
    padding-top: 113px;
    padding-bottom: 100px;
    width: 100%;
    overflow: hidden;
}

.features_header{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 35px;
    line-height: 20px;
    text-align: center;

    color: #171F17;
}

.feature_bg{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 103px;
    padding-left: 210px;
    padding-right: 210px;
}

.feature_bg img{
    width: 1026.03px;
    box-shadow: 0px 0px 75px 10px #A9C1A9;
    border-radius: 20px;
}

/***************
***************
***************
***************
***************
***************
***************/

.svgExpand{
    cursor: pointer;
}
.expandMSG{
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10.95px;
}
.messagebox{
    padding: 0;
    background: #E2E1D7;
    opacity: 0.95;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 0;
    height: 0;
    transition: all 0.4s ease;
    position: absolute;
    z-index: 2;
    opacity: 1;
    transform-origin: left center;
}
.activeMSG{
    padding: 14px 32px;
    width: fit-content;
    height: fit-content;
    transition: all 0.4s ease;
}


/**1**/
.firstMSG{
    left:23%;
    top:23%;
}

.parent1{
    position: absolute;
    z-index: 4;
    left: calc(23% + 60px);
    top: 23%;
    transition: all 0.4s ease;
}

@media (min-width:1600px) {
    .firstMSG{
        left:29%;
        top:23%;
    }
    .parent1{
        position: absolute;
        left: calc(29% + 60px);
        top: 23%;
        transition: all 0.4s ease;
    }
}

/**2**/
.secondMSG{
    left: 25%;
    top: 84%;
}

.parent2{
    position: absolute;
    top: 84%;
    z-index: 4;
    left: calc(25% + 60px);
    transition: all 0.4s ease;
}

@media (min-width:1600px) {
    .secondMSG{
        left: 32%;
        top: 84%;
    }
    .parent2{
        position: absolute;
        top: 84%;
        left: calc(32% + 60px);
        transition: all 0.4s ease;
    }
}

/**3**/
.thirdMSG{
    left: 29%;
    top: 59%;
}
.parent3{
    display: flex;
    z-index: 4;
    position: absolute;
    top: 59%;
    left: calc(29% + 60px);
}
@media (min-width:1600px) {
    .thirdMSG{
        left: 35%;
        top: 59%;
    }
    .parent3{
        display: flex;
        position: absolute;
        top: 59%;
        left: calc(35% + 60px);
    }
}

/**4**/
.fourthdMSG{
    left: 57%;
    top: 69%;
}

.parent4{
    position: absolute;
    z-index: 4;
    left: calc(57% + 60px);
    top: 69%;
}

@media (min-width:1600px) {
    .fourthdMSG{
        left: 55%;
        top: 69%;
    }
    .parent4{
        position: absolute;
        left: calc(55% + 60px);
        top: 69%;
    }
}


/**5**/
.fifthdMSG{
    top:23%;
    left: 63%;
}

.parent5{
    position: absolute;
    z-index: 4;
    left: calc(63% + 60px);
    top: 23%;
}
@media (min-width:1600px) {
    .fifthdMSG{
        top:23%;
        left: 60%;
    }
    .parent5{
        position: absolute;
        left: calc(60% + 60px);
        top: 23%;
    }
}



/**6**/
.sixthdMSG{
    top: 38%;
    left: 45%;
}
.parent6{
    position: absolute;
    z-index: 4;
    left: calc(45% + 60px);
    top: 38%;
}
@media (min-width:1600px) {
    .sixthdMSG{
        bottom: 55%;
        left: 46%;
    }
    .parent6{
        position: absolute;
        left: calc(46% + 60px);
        top: 38%;
    }
}


/**7**/
.seventhMSG{
    top: 8%;
    left: 45%;
}
.parent7{
    position: absolute;
    z-index: 4;
    top: 8%;
    left: calc(45% + 60px);
}
@media (min-width:1600px) {
    .seventhMSG{
        top: 8%;
        left: 46%;
    }
    .parent7{
        position: absolute;
        top: 8%;
        left: calc(46% + 60px);
    }
}


/******* ტექსტები  ***/

.msgText1{
    font-family: 'BPG Nino Mtavruli';
    text-align: center;
    width: 140px;
    opacity: 0;
}

.msgText2{
    font-family: 'BPG Nino Mtavruli';
    width:182.78px;
    text-align: center;
    opacity: 0;
}

.msgText3{
    font-family: 'BPG Nino Mtavruli';
    width:212px;
    text-align: center;
    opacity: 0;
}

.msgText4{
    font-family: 'BPG Nino Mtavruli';
    width:226px;
    text-align: center;
    opacity: 0;
}

.msgText5{
    font-family: 'BPG Nino Mtavruli';
    width:142.72px;
    text-align: center;
    opacity: 0;
}

.msgText6{
    font-family: 'BPG Nino Mtavruli';
    width:180px;
    text-align: center;
    opacity: 0;
}

.msgText7{
    font-family: 'BPG Nino Mtavruli';
    width:180px;
    text-align: center;
    opacity: 0;
}



.activeTXT{
    opacity: 1;
    transition: all 0.4s ease;
    transition-delay: 0.05s;
}

@media (max-width:769px) {
    .features{
        box-sizing: border-box;
        padding-top: 76px;
        padding-bottom: 100px;
        width: 100%;
        overflow: hidden;
    }

   

    .feature_bg{
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 58px;
    }
    .feature_bg img{
        width: 100%;
        box-shadow: 0px 0px 2px rgba(2, 159, 160, 0.7);
        border-radius: 20px;
    }
    .features_header{
        font-size: 17px;
    }

    .svgExpand{
        width: 30px;
        height: 30px;
    }

    .parent1,
    .parent2,
    .parent3
    {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateX(50%);
    }

    .parent4{
        background-color: red;
        left: 0;
        top: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .parent5{
        width: 100%;
        left: 0;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .parent6{
        width: 100%;
        left: 0;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .parent7{
        width: 100%;
        left: 0;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .firstMSG{
        left:10%;
        top:27%;
    }

    .secondMSG{
        left: 13%;
        top: 84%;
    }

    .thirdMSG{
        left: 24%; 
        top: 57%;
    }

    .fourthdMSG{
        left: 57%;
        top: 63%;
    }

    .fifthdMSG{
        top:19%;
        left: 65%;
    }

    .sixthdMSG{
        top: 33%;
        left: 41%;
    }

    .seventhMSG{
        top: 6%;
        left: 48%;
    }
}