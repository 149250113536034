.slider{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #A9C1A9;
}

.slider_inner{
    width: 100%;
    height: 100%;
    position: absolute;
    height: 100%;
    transition: all 0.4s ease;
}

.act{
    height: 100%;
}

.slider_img{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    object-position: center center;
    transition: all 1s ease;
    overflow-y: hidden;
    transform: scale(1.1)
}

.slider_active{
    opacity: 1;
    overflow-y: hidden;
    transform: scale(1);
    transition: all 1s ease;
}


.mapping-div{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

@keyframes sldierAnimation {
    80%{
        transform: scale(1);
        opacity: 1;
    }
    
    100%{
        transform: scale(1.1);
        opacity: 0;
    }
}

@keyframes sldierAnimationBack {
    80%{
        transform: scale(1.1);
        opacity: 0;
    }
    
    100%{
        transform: scale(1);
        opacity: 1;
    }
}

.prev_button,
.next_button{
    position: absolute;
    z-index: 1;

    top: 151px;
    width: 40px;
    height: 40px;

    border-radius: 50%;
    border: 1px solid transparent;
    box-sizing: border-box;

    background-color: #029FA0;
    transition: all 0.4s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: all 0.4s ease;
}

.prev_button:hover,
.next_button:hover{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.prev_button{
    right: 121.68px;
}
.next_button{
    right: 68.8px;
}


.svg{
    stroke: #fff;
    transition: all 0.4s ease;
}


.leftArrow{
    transform: rotate(180deg);
}


.dots{
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 26px;
    left: 0;
    display: flex;
    justify-content: center;
    gap: 13px;
}

.dot{
    width: 7px;
    height: 7px;
    background-color: #fff;
    opacity: 0.5;
    border-radius: 50%;
    cursor: pointer;
}

.slider_header{
    position: absolute;
    z-index: 1;
    bottom: 222px;
    left: 75px;

    color: #FFFFFF;
    font-family: 'BPG Nino Mtavruli';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 58px;
}

.slider_paragraph{
    position: absolute;
    z-index: 1;
    bottom: 150px;
    left: 75px;

    font-family: 'BPG Mrgvlovani';
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: #FFFFFF;
    opacity: 0;

    transition: all 0.8s ease;
}

.text_active{
    opacity: 1;
    bottom: 184px;
}

.slider_btn{
    position: absolute;
    z-index: 1;

    width: 168.02px;
    height: 50.01px;
    left: 75px;
    bottom: 51px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #029FA0;
    border-radius: 30px;

    cursor: pointer;
    transition: all 0.4s ease;
}

.slider_btn:hover{
    bottom: 61px;
    box-shadow: 0px 0px 25px #029FA0;
}

.slider_btn span{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 3px;
    transition: all 0.4s ease;
}


@media (max-width:769px) {
    .slider_header{
        bottom: 140px;
        display:inline;
        width: 100%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
        color: #FFFFFF;
        font-family: 'BPG Nino Mtavruli';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
    }
    .slider_paragraph{
        
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;

        bottom: 70px;
        position: absolute;
        font-size: 11px;

        font-family: 'BPG Mrgvlovani';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        color: #FFFFFF;
    }
    
    .text_active{
        opacity: 1;
        bottom: 102px;
    }
    
    .slider_btn{
        width: 100px;
        height: 40px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 35px;
    }

    .slider_btn span{
        font-family: 'BPG Nino Mtavruli';
        font-weight: 700;
        font-size: 13px;
        color: #FFFFFF;
    
        transition: all 0.4s ease;
    }
    .prev_button,
    .next_button{
        display: none;
    }
    .slider_btn:hover{
        bottom: 35px;
        box-shadow: none;
    }

    .dots{
        bottom: 11px;
    }
}
