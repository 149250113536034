.flats{
    width: 100%;
    padding-left: 36px;
    padding-right: 36px;
    box-sizing: border-box;
    padding-top: 120px;
    padding-bottom: 135px;
    overflow: hidden;
}

.headers_and_more{
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flats_header{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;

    color: #171F17;
}

.more_flats{
    font-family: 'BPG Nino Mtavruli';
    position: relative;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    transition:all 0.4s ease;
}

.cards{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
    margin-top: 146px;
}


.card_outter{
    position: relative;
}

.card{
    position: relative;
    width: 424px;
    height: 484px;
    background: #E6ECDF;
    border-radius: 70px;

    overflow: hidden;
    cursor: pointer;
    transition: all 0.4s ease;
}

.card:hover{
    margin-top: -20px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
}

.card_inner_top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_inner_bottom{
    position: absolute;
    bottom: 0;
    padding-bottom: 72px;
    background-color: #E6ECDF;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 31px;
}

.card_render_main_screen{
    width: 420px;
    height: 260px;
    margin-top: 33px;
    object-position: center center;
    object-fit:scale-down;
    transition: all 0.4s ease;
}

.personalStyle{
    margin-right: 30px;
}

.card_inner_bottom_left,
.card_inner_bottom_right{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
}

.card_inner_bottom_p{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #181B41;
}

.bed_Icon{
    width: 33px;
    height: 31px;
}

.mc_Icon{
    width: 29px;
    height: 31px;
}

.btnOuter{
    width: 100%;
    height: 55.49px;
    margin-top: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seeMoreBtn{
    width: 173.74px;
    height: 55.49px;

    display: flex;
    align-items: center;
    justify-content: center;


    background: #D8E5D6;
    border-radius: 30px;

    cursor: pointer;
    transition: all 0.4s ease;
}

.seeMoreBtn:hover{
    margin-top: -20px;
    box-shadow: 0px 0px 50px rgba(43, 28, 80, 0.25);
}

.seeMoreBtn p{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;

    color: #2B1C50;
}

.leftBtn,
.rightBtn{
    position: absolute;
    bottom: -26.5px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.leftBtn{
    left: 107px;
}
.rightBtn{
    right: 107px;
}

.card_outer_div{
    position: relative;
}

.swiper_slider_responsive{
    display: none;
}


/* სლაიდერი */
.swiper {
    width: 100%;
    display: flex;
    margin-left: -50px;
    padding: 0;
  }
  
  .mySwiper{
      display: flex;
  }
  
  
  .swiper-slide {
    overflow: hidden;
    position: relative;
    width: 329px;
    height: 342px;
    padding-top: 10px;
    padding-bottom: 50px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .maping_cards{
    margin-top: 0px !important;
  }
  


@media (max-width:769px) {
    .flats{
        width: 100%;
        padding-left: 36px;
        padding-right: 36px;
        box-sizing: border-box;
        padding-top: 120px;
        padding-bottom: 135px;
        overflow: hidden;
    }
    .cards{
        gap: 15px;
        margin-top: 36px;
        padding: 50px;
    }
    .card{
        width: 330px;
        height: 340px;
    }
    .card:hover{
        margin-top: 0px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
    .card_render_main_screen{
        width: 315px;
        height: 209px;
        object-fit: scale-down;
        margin-top: 30px;
    }
    .personalStyle{
        margin-right: 30px;
    }
    .card_inner_bottom{
        padding-bottom: 50px;
    }
    .bed_Icon{
        width: 26px;
        height: 25px;
    }
    
    .mc_Icon{
        width: 26px;
        height: 25px;
    }
    .card_inner_bottom_p{
        font-family: 'BPG Nino Mtavruli';
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        color: #2B1C50;;
    }
    .flats_header{
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        color: #171F17
    }
    .flats{
        padding-top: 76px;
        padding-bottom: 100px;
    }
    
    .swiper_slider_responsive{
        display: flex;
    }
    .btnOuter{
        margin-top: 0;
    }
    .maping_cards{
        display: none;
    }

}















  