.contact_form_btn{
    width: 803px;
    height: 55px;
    

    background: #029FA0;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    z-index: 4;
    border: 0;
    margin-top: 68px;
}

.email_for_form{
    width: 803px !important;
    height: 55px !important;
}

.upper_input{
    width: 385px !important;
    height: 55px !important;
}

.leave_contact{
    margin-bottom: 71px;

    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 25px;

    color: #171F17;
}

.sale_Header{
    margin-bottom: 30px;

    text-align: center;
    font-size: 35px;
    font-family: 'BPG Nino Mtavruli';
    font-weight: 900;
    font-size: 35px;

    color: #171F17;
}

.leave_contact_small{
    margin-bottom: 50px;

    text-align: center;
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 20px;

    color: #171F17;
}


@media (max-width:769px) {
    .email_for_form{
        width: 100% !important;
        height: 40px !important;
    }
    .upper_input{
        width: 100% !important;
        height: 40px !important;
    }
    .contact_form_btn{
        width: 100%;
    }

    .leave_contact{
        margin-bottom: 71px;
        margin-top: 100px;
        text-align: center;
    
        font-family: 'BPG Nino Mtavruli';
        font-weight: 700;
        font-size: 25px;
    
        color: #171F17;
    }

    .sale_Header{
        margin-top: 75px;
        font-size: 29px;
    }
    
    .leave_contact_small{
        font-size: 16px;
    }

    .placeholder_number_error_rus_contactFrom{
        bottom: -20px !important;
        right: 0px !important;
        left: 10px;
        width: 100%;
    }
    
}


