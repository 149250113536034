.Navbar{
  position: fixed;
  z-index: 6;
  top: 0;

  padding-left: 75px;
  padding-right: 75px;

  width: 100%;
  height: 100px;
  box-sizing: border-box;
  
  background-color:#FFFFFF;
  box-shadow: 0px 4px 55px 30px rgba(0, 0, 0, 0.05);
}

.navbar_inner{
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.logo{
    cursor: pointer;
    height: 45.54px;
    width: auto;
}

.menu_and_langs{
    height: 100%;
    
    display: flex;
    align-items: center;
    gap: 80px;
}

.nav_menu{
    display: flex;
    gap: 40px; 
}

.menu_links{
    position: relative;
    list-style: none;
    cursor: pointer;
}

.languages{
    display: flex;
    gap: 2px;
}

.active_page{
    color: #029FA0;
}

.languages span{
    cursor: pointer;
}

.active_language{
    color: #029FA0;
}

.burgerMenu{
    width: 45px;
    height: 45px;

    background-color: #E6ECDF;
    border-radius: 50%;

    cursor: pointer;

    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
}

.burgerMenu_panel{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #ffffff;
    top: -200%;
    z-index: 5;
    margin-top: 88px;
    padding-top: 102px;

    display: flex;
    flex-direction: column;
    gap: 45px;

    transition: all 0.4s ease;
}

.burgerMenu_panel_active{
    top: 0%; 
    transition: all 0.4s ease;
}

.burgerMenu_link{
    font-family: 'BPG Mrgvlovani';
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    
    margin-left: 30px;
    margin-right: 30px;
}

.burger_languages{
    height: 90px;
    width: 100%;
    background-color: #E6ECDF;
    position: absolute;
    bottom: 88px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}


@media (max-width:769px) {
    .Navbar{
        padding-left: 30px;
        padding-right: 30px;
        height: 90px;
    }
    .nav_menu{
        display: none;
    }
    .languages{
       display: none;
    }
    .burgerMenu{
        display: flex;
    }
    .logo{
        height: 40px;
    }
}

