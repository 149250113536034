*{
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li{
  list-style: none;
  cursor: pointer;
}

a{
  text-decoration: none;
  color: unset;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 16px;
}


::-webkit-scrollbar-thumb{
  background-color: #858384;
  border-radius: 60px;
  width: 10px;
  height: 55px;
}


@font-face {
  font-family: 'BPG Mrgvlovani';
  src: url('../src/fonts/bpg_mrgvlovani_caps_2010.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'bpg web 001';
  src: url('../src/fonts/bpg-web-001-caps-webfont.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'BPG Nino Mtavruli';
  src: url('../src/fonts/bpg_nino_mtavruli_normal.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BPG Arial';
  src: url('../src/fonts/bpg_aria.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../src/fonts/Roboto-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoFlex';
  src: url('../src/fonts/RobotoFlex.ttf');
  font-display: swap;
}

.overflowHidden{
  overflow: hidden;
}

.overflowAdd{
  overflow: scroll;
}

::selection {
  background-color: #029FA0;
}


@media screen and (max-width: 768px) {
  #fb-customer-chat {
    display: none !important;
  }
  .fb-customerchat{
    display: none;
 }
}