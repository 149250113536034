.apartments_page{
    padding-top: 240px;
    padding-bottom: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.apartment_header{
    margin-bottom: 116px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'bpg web 001';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    color: #171F17;
}

.apartment_list{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* //////// */
    gap: 110px 30px;
    padding: 0 46px;
}

.outerRender{
    width: 420px;
    height: 260px;
    margin-top: 33px;
    object-position: center center;
    object-fit:scale-down;
    transition: all 0.4s ease;
}

@media (max-width:1399px) {
    .apartment_list{
        gap: 110px 30px;
        padding: 0 60px;
    }

    .cardinpage{
        width: 390px;
        height: 450px;
    }
}


@media (max-width:769px) {
    .cardinpage{
        box-shadow: 0px 0px 15px 2px rgba(2, 159, 160, 0.4);
        width: 330px !important;
        height: 340px !important;
    }
    .apartments_page{
        padding-top: 0px;
        padding-bottom: 120px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }

    .apartment_to__back{
        padding-top: 130px;
        padding-left: 15px !important;
        justify-items: flex-start;
    }

    .apartment_list{
        width: 99%;
        justify-content: center;
    }
    .apartment_header{
        margin-bottom: 70px;
        font-size: 18px;
        font-family: 'BPG Nino Mtavruli';
        font-weight: 700;
        justify-content: center;
        padding-left: 0px;
    }
    .outerRender{
        width: 315px !important;
        height: 209px !important;
        margin-top: 33px;
        object-position: center center;
        object-fit:scale-down;
        transition: all 0.4s ease;
    }
}



@media (min-width:1600px) {
    .apartment_list{
        width: calc(100% - 70px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;
        gap: 110px 60px;
        padding: 0 220px;
    }
} 


.positioningOuterRender{
    margin-left: 39px;
}

.position71sec{
    margin-right: 24px;
}