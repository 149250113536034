.pop_up_msg{
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    align-items: center;
    justify-content: center;
    display: none;
    top: 0;
}

.pop_up_active{
    display: flex;
}

.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
}

.form_outer{
    background-color: #fff;
    padding-bottom: 81px;
    padding-left: 71px;
    padding-right: 71px;
    padding-top: 71px;
    position: relative;
    border-radius: 20px;
}

.close{
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
    width: 18px;
    height: 18px;
}

.call_float_btn{
    width: 44px;
    height: 44px;
    position: fixed;
    bottom: 24px;
    right: 80px;
    opacity: 0;
    transition: all 0.4s ease;
    cursor: pointer;
    z-index: -1;
    transition: all 0.4s ease;
    border-radius: 50%;
}

.call_float_btn:hover{
    box-shadow: 0px 0px 15px #029FA0;
}

.call_float_btn_active{
    opacity: 1;
    z-index: 3;
    transition: all 0.4s ease;
}

@media (max-width:769px) {
    .form_outer{
        border-radius: 0px;
    }

    .call_float_btn:hover{
        box-shadow: none;
    }
    .form_outer{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding-bottom: 0px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 0px;
    }
    .close{
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    .form_outer .input_fields_top{
        gap: 50px;
    }

    .form_outer .input_fields{
        gap: 50px;
    }

    .form_outer .error_icon{
        top:10px;
    }

    .call_float_btn{
        bottom: 80px;
        right: 24px;
    }
}


.modal_PopUp{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    align-items: center;
    justify-content: center;
    display: none;
    top: 0;
}

.modal_PopUp_active{
    display: flex;
}

