.footer{
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.running_text_outter{
  display: flex;
  align-items: center;
  background-color: #D8E5D6;
  padding-top: 96px;
  padding-bottom: 96px;
}
.running_text{
    display: flex;
    gap: 85px;
    

    animation-name: ticker;
    -webkit-animation-iteration-count:infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
   -webkit-animation-name: ticker;
           animation-name: ticker;
    -webkit-animation-duration: 50s;
            animation-duration: 50s;
}

.running_text h1{
    display: inline-block;
    white-space: nowrap;
    font-family: 'BPG Nino Mtavruli';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    /* identical to box height */
    color: #181B41;    
}

@keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    100% {
      -webkit-transform: translate3d(-30%, 0, 0);
      transform: translate3d(-50%, 0, 0);
    }

  }

  .footer_bottom{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 75px;
    padding-bottom: 45px;
  }

  .footer_bottom_icons{
    display: flex;
    align-items: center;
    gap: 17px;
  }

  .footer_icon{
    cursor: pointer;
    width: 45px;
    height: 45px;

    background-color: #D8E5D6;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer_icon img{
    width: 18px;
    height: 18px;
  }

  .footer_bottom_number_adrees{
    margin-left: 24px;
    display: flex;
    align-items: center;
    gap: 28px;
  }

  .footer_number{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #181B41;
  }

  .footer_adress{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 400;
    font-size: 12px;
    color: #181B41;
    align-self: center;
  }

  .footer_bottom_rights{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .copyright{
    margin-right: 25px;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    color: #181B41;
  }
  
  @media (max-width:769px) {
    .running_text_outter{
      display: none;
    }

    .footer{
      background-color: #D8E5D6;
    }

    .footer_icon{
      background-color: #CEDACC;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    }
    .footer_bottom{
      flex-direction: column;
      gap: 50px;
      padding-left: 30px;
      padding-right: 30px;
    }
    .footer_bottom_number_adrees{
      margin-left: 0px;
    }
    .footer_bottom_rights{
      flex-grow: 0;
      justify-content: flex-start;
      margin-top: -30px;
    }
    .copyright{
      margin-right: 0px;
    }
  }