
.about_inner{
    box-sizing: border-box;
    overflow-x: hidden;
    width: 100%;
    background-color: #A9C1A9;
    padding-top: 220px;
    padding-bottom: 210px;
    padding-left: 75px;
    padding-right: 60px;

    font-family: 'BPG Nino Mtavruli';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;

    display: flex;

    color: #171F17;
}

.about_inner_left{
    width: 50%;
}

.about_us_header{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;

    color: #171F17;
}

.about_inner_left_texts{
    box-sizing: border-box;
    margin-top: 70px;
    width: 535.73px;
    display: flex;
    line-height: 24px;
    flex-direction: column;
    gap: 30px; 
}

.about_us_list{
    list-style:inside;
    font-weight: bold;
}

.about_inner_left_texts ul{
    margin-left: 10px;
}

.about_inner_right{
    box-sizing: border-box;
    width: 50%;
    display: flex;
    padding-bottom: 7px;
    padding-right: 15px;
}

.aboutImage{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.aboutImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: 0px 0px 25px 10px rgba(2, 159, 160, 0.5);
}

.about_to_back{
    padding-left: 30px !important;
    margin-top: 150px;
}

@media (max-width:769px) {
    .about_inner{
        flex-direction: column;
        gap: 60px;
        padding-top: 0px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 100px;
        background-color: #fff;
    }
    .about_inner_left{
        box-sizing: border-box;
        width: 100%;
        padding-right: 40px;
    }

    .about_us_header{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #171F17;
    }

    .about_inner_left_texts{
        margin-top: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        font-size: 12px;
    }
    .about_inner_right{
        width: 100%;
        display: flex;
    }
}

@media (min-width:1600px) {
    .aboutImage img{
        width: 600px;
        height: 645px;
        object-fit: cover;
        border-radius: 30px;
        box-shadow: 0px 0px 25px 10px rgba(2, 159, 160, 0.5);
    }
    .about_inner{
        padding-left: 330px;
        padding-right: 330px;
    }
}