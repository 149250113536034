.why_panorama{
    box-sizing: border-box;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    max-width: 100%;

    background-color: #A9C1A9;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 320px;
    
    padding-top: 100px;
    padding-bottom: 80px;
    padding-left: 75px;
    padding-right: 75px;
}

.why_panorama{
    box-sizing: border-box;
}

.header_txt_btn{
    width: 478.18px;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.why_panorama_h1{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;

    color: #2B1C50;
}

.why_panorama_txt{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 31px;
    /* or 155% */

    display: flex;
    align-items: center;

    color: #2B1C50;
}

.why_panorama_btn{
    margin-top: 42px;
    width: 478.25px;
    height: 75.91px;
    box-sizing: border-box;
    
    background: #E6ECDF;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8.4px;
    cursor: pointer;
}

.why_panorama_btn_icon{
    width: 28px;
    height: 28px;
}

.why_panorama_btn_paragraph{
    position: relative;
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 31px;

    color: #1F271F;
}

.why_panorama_btn_paragraph::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    border: 1px solid #1F271F;
}

.why_panorama_btn_responsive{
    width: 330.02px;
    height: 55px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;

    background: #E6ECDF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 45px;

    display: none;
    align-items: center;
    justify-content: center;
}

.why_panorama_btn_paragraph_responsive{
    font-family: 'BPG Nino Mtavruli';
    font-weight: 700;
    font-size: 15px;

    display: flex;
    align-items: center;

    color: #2B1C50;
}

.float_cliff{
    height: 600px;
    width: auto;
}

.hidenspan{
    display: none;
}


@media (max-width:769px) {
    .header_txt_btn{
        width: 313.82px;
    }
    .why_panorama{
        flex-direction: column;
        height: fit-content;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;
        gap: 50px;
    }
    .why_panorama_btn{
        display: none;
    }

    .float_cliff{
        height: unset;
        width: 80%;
    }

    .why_panorama_h1{
        font-family: 'BPG Mrgvlovani';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 40px;
        color: #2B1C50;
        font-weight: 700;
    }
    .why_panorama_btn_responsive{
        display: flex;
    }
    .float_cliff{
        height: 395px;
        width: auto;
    }
    .why_panorama_txt{
        font-family: 'BPG Mrgvlovani';
        font-size: 13px;
    }

}