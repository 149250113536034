.contact{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    width: 100%;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 105px;
    padding-top: 60px;
    gap: 100px;
}

@media (min-width:1600px) {
    .contact{
        padding-left: 250px;
        padding-right: 250px;
    }
}

.contact_left{
    display: flex;
    flex-direction: column;
    gap: 75px;
}

.contact_header{
    font-family: 'BPG Nino Mtavruli';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 52px;
    /* identical to box height */
    color: #171F17;
}

.contact_paragraph{
    width: 514px;
    height: 81px;
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: #171F17;
}

input{
    box-sizing: border-box;
    padding-left: 18px;
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #171F17;
    transition: all 0.4s ease;
}

input:focus{
    background-color:none;
    transition: all 0.4s ease;
    outline: none;
}

.submit_btn{
    margin-top: 73px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 45px;
}

.submit_btn button{
    width: 145.6px;
    height: 45.52px;
    background: #029FA0;
    border: 4px solid #91CCCC;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    cursor: pointer;
}

button span{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: #FFFFFF;
}

.input_fields{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 72px;
    width: 100%;
    max-width: 100%;
}

.input_fields_top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.input_fields_bottom{
    position: relative;
    box-sizing: border-box;
    width: 100%;
}

.input_fields_top input{
    width: 305px;
    height: 55px;
    box-sizing: border-box;

    border: 2px solid #171F17;
    border-radius: 30px;
}

.input_fields_top input:focus{
    border: 2px solid #029FA0;
    background-color: #D8E5D6;
}

.input_fields_bottom input{
    width: 635px;
    height: 55px;
    box-sizing: border-box;

    border: 2px solid #171F17 ;
    border-radius: 30px !important;
}

.input_fields_bottom input:focus{
    border: 2px solid #029FA0;
    background-color: #D8E5D6;
}

.input1_label1{
    position: relative;
}
.placeholder_name{
    font-family: 'BPG Mrgvlovani';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    
    position: absolute;
    top: -35px;
    left: 33px;
}

.input2_label2{
    position: relative;
}
.placeholder_number{
    font-family: 'BPG Mrgvlovani';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    
    position: absolute;
    top: -35px;
    left: 33px;
}
.placeholder_number_error{
    font-family: 'BPG Mrgvlovani';
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #9B0D0D;
    
    position: absolute;
    bottom: -35px;
    right: 33px;

    display: none;
}

.placeholder_number_error_rus{
    bottom: -30px !important;
    right: 0px !important;
    left: 10px;
    width: 100%;
}

.placeholder_email_error{
    font-family: 'BPG Mrgvlovani';
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #9B0D0D;
    
    position: absolute;
    bottom: -35px;
    left: 33px;

    display: none;
}

.placeholder_email_ok{
    bottom: -38px;
    left: 33px;
    position: absolute;
    width: 441.06px;
    height: 16px;

    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: none;
    color: green;
}

.placeholder_email{
    font-family: 'BPG Mrgvlovani';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    
    position: absolute;
    top: -35px;
    left: 33px;
}

.error_icon{
    position: absolute;
    right: 23px;
    top:15.87px;

    display: none;
}




@media (max-width:1400px) {
    .contact{
        gap:66px;
    }

    .input_fields_top input{
        width: 255px;
        height: 55px;
    }

    .input_fields_bottom input{
        width: 535px;
        height: 55px;
        box-sizing: border-box;
    
    
        border: 2px solid #171F17;
        border-radius: 15px;
    }

    .placeholder_number_error{
        font-family: 'BPG Mrgvlovani';
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #9B0D0D;
        
        position: absolute;
        bottom: -35px;
        right: 20px;
    
        display: none;
    }
}

@media (max-width:769px) {
    .contact{
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
    }
    .contact_left{
        gap: 21px;
    }
    .contact_header{
        font-family: 'BPG Nino Mtavruli';
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        color: #171F17;
    }
    .contact_paragraph{
        max-width: 98%;
        font-family: 'BPG Mrgvlovani';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #171F17;
    }

    .input_fields{
        gap: 65px;
    }
    .input_fields_top{
        flex-direction: column;
        gap: 65px;
    }

    .input_fields_top input{
        width: 100%;
    }

    .input_fields_bottom input{
        width: 100%;
    }
    .submit_btn button{
        width: 100%;
        height: 55px;
    }
    .submit_btn{
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;
        height: 45px;
    }

    .placeholder_name{
        top: -24px;
    }
    .placeholder_number{
        top: -24px;
    }
    .placeholder_email{
        top: -24px;
    }

    .placeholder_number_error{
        bottom: -24px;
        right: 20px;
    }
    
    .placeholder_email_error{
        bottom: -24px;
        left: unset;
        right: 20px;
    }

    .placeholder_email_ok{
        bottom: -30px;
        left: 15px;
        right: 0;
        font-size: 10px;
    }
    .rusFontDecrease{
        font-size: 16px !important;
        font-weight: 700 !important;
    }
}