.about_proj_pageOuter{
    width: 100%;
    height: 100vh;
    padding-top: 70px;
    overflow-x:hidden;
}

.about_proj_page{
    box-sizing: border-box;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 202px;
    gap: 37px;
    padding-left:70px ;
    padding-bottom: 110px;

    background-image: url('../../images/aboutProjCover.webp');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    filter: brightness(10%);
    transition: all 1.6s ease;
}

.about_proj_page_active{
    filter: brightness(100%);
}

.about_proj_page_header{
    font-family: 'bpg web 001';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 41px;
    color: #FCFCFC;
}

.about_proj_page_p{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #F4F3E7;
}

.map{
    box-sizing: border-box;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    transition: all 1.4s ease;
    padding-left: 75px;
    padding-right: 75px;

    
}

iframe{
    background-color: #FFFFFF;
    border: none;
}

.map_frame{
    width: 100%;
    height: 500px;
    border-radius: 50px;

    box-shadow: 0px 10px 60px rgba(2, 159, 160, 0.2);
}

.map_active{
    margin-top: -50px;
}


.rangesDiv{
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    position: relative;
    margin-top:447px ;
    padding-bottom:150px;
}

.range_msg_box{
    position: absolute;
    width: fit-content;
    height: fit-content;
    padding-left: 20px;
    padding-right: 18px;
    padding-top: 15px;
    padding-bottom: 11px;
    background: #029FA0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleTXT{
    font-weight: 700;
    font-size: 15px;
    font-family: 'BPG Nino Mtavruli';
    color: #000000;
}   

/* წრე ერთი */
.circle_txt1{
    position: absolute;
    top: -130px;
    left: 120px;
}
.msgBox1{
    top: -197px;
    left: 113px;
}

.circle1{
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    z-index: 1;
    left: 124px;
    border-radius: 50%;
    transform: translateY(-50%);
}

/* წრე ორი */
.circle2{
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    z-index: 1;
    left: 392px;
    border-radius: 50%;
    transform: translateY(-50%);
}
.circle_txt2{
    position: absolute;
    top: 126px;
    left: 340px;
}

.msgBox2{
    top: 150px;
    left: 245px;
}

.msgBox2_eng{
    left: 285px;
}

.msgBox2_rus{
    left: 295px;
}

/* მესამე წრე */
.circle3{
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    z-index: 1;
    left: 1180px;
    border-radius: 50%;
    transform: translateY(-50%);
}
.circle_txt3{
    position: absolute;
    top: -130px;
    left: 668px;
}
.circle_txt3_rus{
    right: 25.5%;
}
.msgBox3{
    top: -197px;
    left:656px;
}


/* წრე მეოთხე */
.circle4{
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: #029FA0;
    border: 1px solid black;
    z-index: 1;
    left: 671px;
    border-radius: 50%;
    transform: translateY(-50%);
}
.circle_txt4{
    position: absolute;
    top: 126px;
    
    left: calc(1115px + 0%);
}
.circle_txt4_rus{
    position: absolute;
    
}
.msgBox4{
    top: 150px;
    left: 1000px;
}

.msgBox4_eng{
    left: 1045px;
}

.msgBox4_rus{
    left: 1080px;
}


.line1{
    position: absolute;
    transform: translateY(-100%) translateX(14px);
    opacity: 1;
    transition: all 0.4s ease;
    height: 74px;
}

.line2{
    position: absolute;
    transform: translateY(30px) translateX(14px);
}
.line3{
    position: absolute;
    transform: translateY(30px) translateX(14px);
}
.line4{
    position: absolute;
    transform: translateY(-100%) translateX(14px);
}


.msgBoxText{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

.backRange{
    position: absolute;
    width: 100%;
    background-color: none;
    -webkit-appearance: none;
    padding: 0;
    height: 0px;
    border: 1px solid transparent;
    border-image: url('../../images/rangeDashLine.png') 100;
    z-index: 0;
}

.backRange::-webkit-slider-thumb{
    display: none;
}

.inputRange{
    position: absolute;
    right: 0;
    width: 100%;
    background-color: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    z-index: 2;
    height: 0px;
}

.inputRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    z-index: 2;
    background-image: url('../../images/sliderBtnBg.png');
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
  }
  
  .inputRange::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    z-index: 2;
    background-image: url('../../images/sliderBtnBg.png');
    background-size: cover;
    cursor: pointer;
  }


  .project_timeline_txt{
    padding-top: 190px;
    display: flex;
    flex-direction: column;
    gap: 37px;
    padding-left: 73px;
  }

  .project_timeline_txt h2{
    font-family: 'bpg web 001';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 41px;
    color: #171F17;
  }

  .project_timeline_txt p{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
  }


  /*************************/
  .ganvadeba{
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 195px;
    width: 100%;
    margin-top: 196px;
  }

  .ganvadeba_inner{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 395px;
  }

  .ganvadeba_left{
    box-sizing: border-box;
    width: 665.99px;
    display: flex;
    flex-direction: column;
    gap: 70px;
    padding-left: 70px;
    justify-content: center;
  }

  #para{
    margin-bottom: -10px;
  }

  .ganvadeba h2{
    font-family: 'BPG Nino Mtavruli';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 41px;
    margin-left: 70px;
    /* identical to box height */
    margin-bottom: -50px;
    
    color: #000000;
  }

  .ganvadeba_left p{
    font-family: 'BPG Mrgvlovani';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 35px;
    /* or 175% */
    
    
    color: #000000;
  }


  .ganvadeba_right img{
    width: 100%;
  }

  .about_proj_to_back{
    position: absolute;
    top:130px;
    left:0px;
    color: #fff;
  }
  
 @media (min-width:1900px) {
    .ganvadeba_inner{
        padding-left: 100px;
        padding-right: 100px;
        display: flex;
      }
      .ganvadeba h2{
        margin-left: 170px;
      }
 }


  @media (max-width:769px) {
    .ganvadeba_inner{
        flex-direction: column;
        height: fit-content;
        margin-top: 34px;
      }
      .ganvadeba h2{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
        margin-left: 25px;
        /* identical to box height */
        margin-bottom: 0px;
       
      }
    
    .about_proj_page{
        padding-left: 20px;
        padding-top: 210px;
        padding-bottom: 86px;
        gap: 7px;
    }
    .project_timeline_txt{
        padding-top: 70px;
        padding-left: 20px;
        gap: 23px;
    }
    .map{
        padding-left: 25px;
        padding-right: 25px;
    }
    .map_frame{
        width: 100%;
        height: 217px;
    }
    .about_proj_page_header{
        font-size: 25px;
    }
    .about_proj_page_p{
        font-size: 10px;
    }
    .second_p{
        display: none;
    }
    .project_timeline_txt h2{
        font-family: 'BPG Nino Mtavruli';
        font-weight: 700;
        font-size: 15px;
    }
    .project_timeline_txt p{
        font-family: 'BPG Mrgvlovani';
        font-size: 10px;
        line-height: 16px;
        color: #000000;
    }
    .ganvadeba{
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 86px;
    }
    .ganvadeba_right{
        width: 100%;
    }
    .ganvadeba_left{
        width: 100%;
        padding-left: 25px;
        gap: 20px;
    }
    .ganvadeba_left h2{
        font-family: 'bpg web 001';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;

        color: #000000
    }

    .ganvadeba_left p{
        font-family: 'BPG Nino Mtavruli';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        /* or 180% */
        padding-right: 0px;

        color: #000000;
    }

    .respHidden{
        display: none;
    }

    .rangesDiv{
        margin: 0 auto;
        width: 90%;
        position: relative;
        margin-top:200px ;
        padding-bottom:150px;
    }
    .range_msg_box{
        width: 102px;
        height: 23.25px;
    }
    .circleTXT{
        font-size: 8px;
        line-height: 9px;
    }
    .msgBoxText{
        font-size: 8px;
        line-height: 9px;
    }

    /* პირველი წრე */
    .circle_txt1{
        position: absolute;
        top: -70%;
        left: 6.5%;
    }
    .msgBox1{
        top: -90%;
        left: 4.5%;
    }

    /* მეორე წრე */
    .circle_txt2{
        position: absolute;
        top: 85%;
        left: 11%;
    }
    .msgBox2{
        top: 65%;
        left: 8.5%;
    }

    /* მესამე წრე */
    .circle_txt3{
        position: absolute;
        top: -70%;
        right: 22%;
    }
    .msgBox3{
        top: -90%;
        right: 10%;
    }

    /* მეოთხე წრე */
    .circle_txt4{
        position: absolute;
        top: 85%;
        right: 21%;
    }
    .msgBox4{
        top: 65%;
        right: 10%;
    }

    .circle_txt3_rus{
        right: 28%;
       
    }
    
  }


  .custom-range-slider-thumb{
    width: 30px;
    height: 30px;
    background-color: #029FA0;
  }

  .custom-range-slider{
    width: 100%;

    background-color: #171F17;
  }